import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { User, MainLicense, StripeSettingsResponse } from '../interfaces/license.interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { Pageable } from '../interfaces/pageable.interface';
import { LicenseType_B2b, LicenseType_B2c, LicenseStatusTrial, LicenseStatusTrialExpired } from '../constants/constants';
import { BaseService } from './base.service';
import { TenantIdService } from './tenant-id.service';

@Injectable({
    providedIn: 'root',
})
export class LicenseService extends BaseService {
    subject = new Subject<MainLicense>();
    isLoad = true;
    items: User[] = [];
    mainLicense: MainLicense;

    stripePublicKey: string;
    customerType: string;

    constructor(
        httpClient: HttpClient,
        tenantIdService: TenantIdService) { 
        super(tenantIdService, httpClient);
    }

    public getUsers(pageable?: Pageable) {
        let params = new HttpParams();

        if (pageable?.skip)
            params = params.append('skip', (pageable.skip * (pageable?.take ?? 5)).toString());

        if (pageable?.take)
            params = params.append('take', pageable.take.toString());

        return this.tenantUrl
            .pipe(
                switchMap(url => this.apiGetWithResponse<User[]>(`${url}/license/users`, params))
            );
    }

    public addUser(user: User) {
        delete user.id;

        return this.tenantUrl
            .pipe(
                switchMap(url => this.apiPost(`${url}/license/users`, user))
            );
    }

    public updateUsers(users: User[]) {
        return this.tenantUrl
            .pipe(
                switchMap(url => this.apiPut(`${url}/license/users`, users))
            );
    }

    public deleteUsers(listId: string[]) {
        const delString =
            '?' + listId.map((item) => 'idsToDelete=' + item).join('&');

        return this.tenantUrl
            .pipe(
                switchMap(url => this.apiDelete(`${url}/license/users${delString}`))
            );
    }

    public getMainLicense(): Observable<MainLicense> {
        return this.tenantUrl
            .pipe(
                switchMap(url => this.apiGet<MainLicense>(`${url}/license`)),
                map((data) => {
                    if (!this.mainLicense) {
                        this.subject.next(data);
                    }
                    this.mainLicense = data;
                    return data;
                })
            );
    }

    public getConstMainLicense(): Observable<MainLicense> {
        if (this.mainLicense) {
            return of(this.mainLicense);
        } else {
            if (this.isLoad) {
                this.getMainLicense();
                this.isLoad = false;
            }
            return this.subject;
        }
    }

    public getStripeSettings(): Observable<StripeSettingsResponse> {
        return this.tenantUrl
            .pipe(
                switchMap(url => this.apiGet<StripeSettingsResponse>(`${url}/license/stripe`))
            );
    }

    public isB2b(): boolean {
        return this.mainLicense?.customerType === LicenseType_B2b;
    }

    public isB2c(): boolean {
        return this.mainLicense?.customerType === LicenseType_B2c;
    }

    public isTrial(): boolean {
        return this.mainLicense?.status === LicenseStatusTrial || this.mainLicense?.status === LicenseStatusTrialExpired;
    }
}
